import React, { Component } from 'react';
import Layout from '../components/layout'
import Footer from '../components/footer'
import SEO from '../components/seo'

import { Row, Col } from 'antd'


class Shalee extends Component {
    constructor(props){
        super(props);
        this.state = {
            navbar: 'top',
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        if(window.scrollY > 100){
            this.setState({navbar: 'no-top'})
        } else {
            this.setState({navbar: 'top'})
        }
    };

    render() {
        return (
            <Layout onScroll={this.handleScroll} position={this.state.navbar} currentPage='shalee'>
                <SEO title="Shalee, l'agent digital équestre" keywords={[`Shalee`,`Application`,`Mobile`,`Cheval`,`Chevaux`,`Club`,`Equestre`,`Equitation`,`Cavalier`]}/>
                <div style={{paddingTop: '100px'}}>
                    <section className="bg-dark ">
                        <Row className="container"  type="flex" justify="space-around" align="middle">
                            <Col xs={34} sm={34} md={12} lg={12} xl={12}>
                                <h1 className="title" >Pourquoi « Shalee » ?</h1>
                                <p style={{fontSize:"16px"}}>
                                L’application est française mais l’histoire est canadienne. Nathanaël, le fondateur, a vécu une expérience incroyable lors d’une excursion à Montréal où il a fait la rencontre d’un étalon pur sang arabe coriace qui n’acceptait ni la selle ni le mors. Personne ne voulait monter cet étalon. Tout le monde le croyait fou. Prêt à relever n’importe quel type de challenges, c’est à cru et en hackamore qu’il le monta. Après de fréquents cabrages et quelques ruades l’étalon s’adoucit et fini, au fil des semaines, par devenir une crème. « La crème de la crème » comme l’affirmera Nate qui fit très vite, de cet étalon, son coup de cœur. De retour en France il n’eut qu’une envie, permettre à un plus grand nombre de vivre une relation unique avec un cheval. Pour se faire, faciliter l’accès à un club équestre devînt sa priorité.
                                </p>
                                <p style={{fontSize:"16px"}}>
                                L’étalon aurait pu s’appeler Bucéphale mais il s’appelait « Shaleemar du boisé ». De cette relation et de cette envie de la partager n’acquit : « Shalee ».
                                </p>
                            </Col>
                            <Col xs={34} sm={34} md={1} lg={1} xl={1}>
                            </Col>
                            <Col xs={34} sm={34} md={11} lg={11} xl={11} style={{minHeight:"315px", minWidth:"300px",margin:"auto"}}>
                                <div style={{position:"absolute", width:"100%"}}>
                                    <iframe width="500" height="315" src="https://www.youtube.com/embed/lUXAeuEpSVc?rel=0&amp;showinfo=0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen style={{position: "absolute", top:"0", left:"0",width:"100%", height:"100%", minHeight:"315px"}}></iframe>
                                </div>
                            </Col>
                        </Row>
                    </section>
                    <Footer/>
                </div>
            </Layout>
        );
    }
}

export default Shalee;